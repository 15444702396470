<template>
  <div id="book_history" class="back-ground-dark">
  <div id="container-general-session">
    <div class="content-upcomming-1">
      <div class="is-flex title-upcomming">
        <span class="text-basier">{{arrBookedAndRequest.length}}</span>
        <p class="text-basier white">Sesiones <br/> pendientes</p>
      </div>
      <!-- Select bookings -->
      <div>
        <select
          v-model="optionsSelect" id="select_bookings"
          class="select select-bookings-1 pr-3">
          <option
            value="all">Pendientes</option>
          <option
            value="history">Pasadas</option>
        </select>
      </div>
    </div>
    <div>
      <div
        v-if="optionsSelect !== 'history' && arrRequestprofessional.length > 0"
        class="is-flex"
        style="margin-bottom: 18px;">
        <img
          class="mr-2"
          src="@/assets/images/booking/point-purple.svg"
          alt="point">
        <p
          class="text-basier gray-emitus is-mini-small-text">
          Necesitas aprobar
            <span
              class="text-basier-bold">
              Hoy
            </span>
        </p>
      </div>

      <!-- history bookings -->

      <ul
        v-if="showRequest"
        class="mb-6">

        <!-- listing to be approved -->

        <li
          v-for="(booking, idx) in arrRequestprofessional"
          :key="idx + 'R'">
          <div
            @click="editBooking(booking._id, booking.professional_id.username)"
            v-if="booking.status === 'request'"
            class="is-flex is-justify-content-space-between mt-4 container-upcomming-listing request-professional"
            >
            <div
              class="content-booking-profesional">
              <div
               class="date-and-hour-booking is-flex">
                <h3
                 class="text-basier">
                 {{booking.startBook}}<span>
                  - {{booking.endBook}}</span></h3>
              </div>
              <div
                class="info-general-booking-listing">
                <div
                 class="name-person-booking"
                v-if="booking.professional_id">
                  <div
                   class="is-flex mt-2"
                   v-if="userId === booking.professional_id._id && booking.client_id">
                    <p
                      v-if="booking.client_id.firstname && booking.client_id.firstname !== ''"
                      class="firts-name text-basier">
                      / {{booking.client_id.firstname}}</p>
                    <p
                     v-else
                     class="class-two text-basier">
                     / Client</p>
                    <p
                     v-if="booking.client_id.lastname && booking.client_id.lastname !== ''"
                     class="ml-1 last-name text-basier">{{booking.client_id.lastname}}</p>
                  </div>
                </div>
                <div
                 v-if="booking.client_id">
                  <div
                   v-if="userId === booking.client_id._id && booking.professional_id" >
                    <p
                      v-if="booking.professional_id.firstname && booking.professional_id.firstname !== ''"
                      class="text-basier">
                      / {{booking.professional_id.firstname}}</p>
                    <p
                     v-else
                     class="text-basier">/ Profesional</p>
                    <p
                     v-if="booking.professional_id.lastname && booking.professional_id.lastname !== ''"
                     class="text-basier">{{booking.professional_id.lastname}}</p>
                  </div>
                </div>

              </div>
            </div>
            <div
             class="is-flex">
              <div
               class="is-flex is-flex-direction-column btn-confirm-bookings">
                <a
                 :class="userId === booking.professional_id._id ? 'btn-to-be-approved-bg' : 'btn-to-be-approved-client-bg'"
                 class="btn-approved dark-gray-emitus text-nunito">
                 Necesitas aprobar</a>
                <div
                 class="is-flex mb-1">
                  <div>
                    <!-- <a
                    class="text-nunito dark-blue-emitus">
                    Videocall in -->
                      <span
                        class="min-missing-videocall text-nunito-SemiBold white mr-2">
                        {{booking.language.substr(0,2)}}
                      </span>
                      <span class="text-basier white">|</span>
                    <!-- </a> -->
                  </div>
                    <img
                      class="ml-2"
                      v-if="userId === booking.professional_id._id"
                      src="https://emituscdn.com/insigniaProfessionalDiner-arrow+(1).svg" alt="insignia profesional">
                    <img
                      class="ml-2"
                      v-if="userId === booking.client_id._id"
                      src="https://emituscdn.com/insigniaClientDiner-arrow+(1).svg" alt="insignia client">
                   <div class="ml-2">
                      <span
                        class="text-basier">{{booking.price}} {{booking.currency === 'USD' ? '$' : '€'}}
                      </span>
                   </div>
                </div>
              </div>
              <div
               class="arrow-status-booking is-flex">
                <img
                 src="@/assets/images/booking/arrow>.svg"
                 alt="arrow">
              </div>
            </div>
          </div>
        </li>
      </ul>

      <!-- listing approved -->

      <div v-if="optionsSelect !== 'history' && arrBookedAndRequest.length > 0" class="is-flex mb-3">
        <img
          class="mr-2"
          src="@/assets/images/booking/point-purple.svg"
          alt="point">
        <p
          class="text-basier"
          style="color: #4F4F4F; font-size: 12px;">
          Siguientes Sesiones
        </p>
      </div>
      <ul v-if="showBooked">
        <li
          v-for="(booking, idx) in arrBookedAndRequest"
          :key="idx + 'B'">

          <!-- Ready Bookings -->
          <div
           v-if="booking.status === 'ready'"
           @click="editBooking(booking._id, booking.professional_id.username)" :class="userId === booking.professional_id._id ? 'ready-professional' : 'ready-client'"
           class="is-flex is-justify-content-space-between mt-4 container-upcomming-listing">
            <div
             class="content-booking-profesional">
              <div
               class="date-and-hour-booking">
                <h3
                 class="text-basier">{{booking.startBook}}
                 <span> - {{booking.endBook}}</span></h3>
              </div>
              <div
               class="info-general-booking-listing">
                <div
                 class="name-person-booking"
                 v-if="booking.professional_id">
                  <div
                   class="is-flex mt-2"
                   v-if="userId === booking.professional_id._id && booking.client_id">
                    <p
                     v-if="booking.client_id.firstname && booking.client_id.firstname !== ''"
                     class="text-basier first-name">
                     / {{booking.client_id.firstname}}
                     </p>
                    <p
                     v-else
                     class="text-basier">
                     / Client
                     </p>
                    <p
                     v-if="booking.client_id.lastname && booking.client_id.lastname !== ''"
                     class="text-basier last-name ml-1">
                     {{booking.client_id.lastname}}
                     </p>
                  </div>
                </div>
                <div
                 v-if="booking.client_id">
                  <div
                   class="is-flex mt-3"
                   v-if="userId === booking.client_id._id && booking.professional_id" >
                    <p
                     v-if="booking.professional_id.firstname && booking.professional_id.firstname !== ''"
                     class="first-name text-basier">
                     / {{booking.professional_id.firstname}}
                     </p>
                    <p
                     v-else
                     class="text-basier">
                     / Profesional
                     </p>
                    <p
                     v-if="booking.professional_id.lastname && booking.professional_id.lastname !== ''"
                     class="last-name ml-2 text-basier">
                     {{booking.professional_id.lastname}}
                     </p>
                  </div>
                </div>
                <!-- <span
                 class="text-basier">{{booking.language}} ・ {{booking.price}} {{booking.currency === 'USD' ? '$' : '€'}}
                 </span> -->
              </div>
            </div>
            <div
             class="content-right is-flex">
              <div
               class="is-flex is-flex-direction-column btn-confirm-bookings">
                <a
                 class="btn-approved btn-ready-bg">
                 Ready to join</a>
                <div
                 class="is-flex mb-1">
                  <div>
                    <!-- <a
                    class="text-nunito dark-blue-emitus">
                    Videocall in -->
                      <span
                        class="min-missing-videocall text-nunito-SemiBold dark-blue-emitus mr-2">
                        {{booking.language.substr(0,2)}}
                      </span>
                      <span class="text-basier white">|</span>
                    <!-- </a> -->
                  </div>
                    <img
                      class="ml-2"
                      v-if="userId === booking.professional_id._id"
                      src="https://emituscdn.com/insigniaProfessionalDiner-arrow+(1).svg" alt="insignia profesional">
                    <img
                      class="ml-2"
                      v-if="userId === booking.client_id._id"
                      src="https://emituscdn.com/insigniaClientDiner-arrow+(1).svg" alt="insignia client">
                   <div class="ml-2">
                      <span
                        class="text-basier">{{booking.price}} {{booking.currency === 'USD' ? '$' : '€'}}
                      </span>
                   </div>
                </div>
              </div>
              <div
               class="arrow-status-booking is-flex">
                <img
                 src="@/assets/images/booking/arrow>.svg"
                 alt="arrow">
              </div>
            </div>
          </div>

          <!-- Approved Bookings -->
          <div
           v-if="booking.status === 'booked'"
           @click="editBooking(booking._id, booking.professional_id.username)" :class="userId === booking.professional_id._id ? 'approved-professional' : 'approved-client'"
           class="is-flex is-justify-content-space-between mt-4 container-upcomming-listing">
            <div
             class="content-booking-profesional">
              <div
               class="date-and-hour-booking">
                <h3
                 class="text-basier">{{booking.startBook}}
                 <span> - {{booking.endBook}}</span></h3>
              </div>
              <div
               class="info-general-booking-listing">
                <div
                 class="name-person-booking"
                 v-if="booking.professional_id">
                  <div
                   class="is-flex mt-2"
                   v-if="userId === booking.professional_id._id && booking.client_id">
                    <p
                     v-if="booking.client_id.firstname && booking.client_id.firstname !== ''"
                     class="text-basier first-name">
                     / {{booking.client_id.firstname}}
                     </p>
                    <p
                     v-else
                     class="text-basier">
                     / Cliente
                     </p>
                    <p
                     v-if="booking.client_id.lastname && booking.client_id.lastname !== ''"
                     class="text-basier last-name ml-1">
                     {{booking.client_id.lastname}}
                     </p>
                  </div>
                </div>
                <div
                 v-if="booking.client_id">
                  <div
                   class="is-flex mt-3"
                   v-if="userId === booking.client_id._id && booking.professional_id" >
                    <p
                     v-if="booking.professional_id.firstname && booking.professional_id.firstname !== ''"
                     class="first-name text-basier">
                     / {{booking.professional_id.firstname}}
                     </p>
                    <p
                     v-else
                     class="text-basier">
                     / Profesional
                     </p>
                    <p
                     v-if="booking.professional_id.lastname && booking.professional_id.lastname !== ''"
                     class="last-name ml-2 text-basier">
                     {{booking.professional_id.lastname}}
                     </p>
                  </div>
                </div>
                <!-- <span
                 class="text-basier">{{booking.language}} ・ {{booking.price}} {{booking.currency === 'USD' ? '$' : '€'}}
                 </span> -->
              </div>
            </div>
            <div
             class="content-right is-flex">
              <div
               class="is-flex is-flex-direction-column btn-confirm-bookings">
                <a
                 class="btn-approved btn-approved-bg">
                 Approved</a>
                <div
                 class="is-flex mb-1">
                  <div>
                    <!-- <a
                    class="text-nunito dark-blue-emitus">
                    Videocall in -->
                      <span
                        class="min-missing-videocall text-nunito-SemiBold dark-blue-emitus mr-2">
                        {{booking.language.substr(0,2)}}
                      </span>
                      <span class="text-basier white">|</span>
                    <!-- </a> -->
                  </div>
                    <img
                      class="ml-2"
                      v-if="userId === booking.professional_id._id"
                      src="https://emituscdn.com/insigniaProfessionalDiner-arrow+(1).svg" alt="insignia profesional">
                    <img
                      class="ml-2"
                      v-if="userId === booking.client_id._id"
                      src="https://emituscdn.com/insigniaClientDiner-arrow+(1).svg" alt="insignia client">
                   <div class="ml-2">
                      <span
                        class="text-basier">{{booking.price}} {{booking.currency === 'USD' ? '$' : '€'}}
                      </span>
                   </div>
                </div>
              </div>
              <div
               class="arrow-status-booking is-flex">
                <img
                 src="@/assets/images/booking/arrow>.svg"
                 alt="arrow">
              </div>
            </div>
          </div>

          <!-- Requested Bookings -->
          <div
           v-if="booking.status === 'request'"
           @click="editBooking(booking._id, booking.professional_id.username)"
           class="is-flex is-justify-content-space-between mt-4 container-upcomming-listing request-client">
            <div
             class="content-booking-profesional">
              <div
               class="date-and-hour-booking is-flex">
                <h3
                 class="text-basier">{{booking.startBook}}
                 <span
                  class="white">
                   - {{booking.endBook}}
                 </span>
                </h3>
              </div>
              <div
                class="info-general-booking-listing">
                <div
                  class="name-person-booking"
                  v-if="booking.professional_id">
                  <div
                    class="is-flex mt-2"
                    v-if="userId === booking.professional_id._id && booking.client_id">
                    <p
                      v-if="booking.client_id.firstname && booking.client_id.firstname !== ''"
                      class="text-basier first-name">
                      / {{booking.client_id.firstname}}
                    </p>
                    <p
                      v-else class="text-basier">
                      / Cliente
                    </p>
                    <p
                     v-if="booking.client_id.lastname && booking.client_id.lastname !== ''"
                     class="last-name text-basier ml-1">
                     {{booking.client_id.lastname}}
                    </p>
                  </div>
                </div>

                <!-- to be approved client -->

                <div
                  class="to-be-approved-client"
                  v-if="booking.client_id">
                  <div
                    class="mt-2 is-flex"
                    v-if="userId === booking.client_id._id && booking.professional_id" >
                    <p
                      v-if="booking.professional_id.firstname && booking.professional_id.firstname !== ''"
                      class="first-name text-basier">
                      / {{booking.professional_id.firstname}}
                    </p>
                    <p
                      v-else class="text-basier">
                      / Profesional
                    </p>
                    <p
                      v-if="booking.professional_id.lastname && booking.professional_id.lastname !== ''"
                      class="last-name ml-1 text-basier">
                      {{booking.professional_id.lastname}}
                    </p>
                  </div>
                </div>
                <!-- <span
                  class="text-basier">
                  {{booking.language}} ・ {{booking.price}} {{booking.currency === 'USD' ? '$' : '€'}}
                </span> -->
              </div>
            </div>
            <div
              class="is-flex">
              <div
                class="is-flex is-flex-direction-column btn-confirm-bookings">
                <a
                  :class="userId === booking.professional_id._id ? 'btn-to-be-approved-bg' : 'btn-to-be-approved-client-bg'"
                  class="btn-approved">
                  Por aprobar
                </a>
                <div
                 class="is-flex mb-1">
                  <div>
                    <!-- <a
                    class="text-nunito dark-blue-emitus">
                    Videocall in -->
                      <span
                        class="min-missing-videocall text-nunito-SemiBold white mr-2">
                        {{booking.language.substr(0,2)}}
                      </span>
                      <span class="text-basier white">| </span>
                    <!-- </a> -->
                  </div>
                    <img
                      class="ml-2"
                      v-if="userId === booking.professional_id._id"
                      src="https://emituscdn.com/insigniaProfessionalDiner-arrow+(1).svg" alt="insignia profesional">
                    <img
                      class="ml-2"
                      v-if="userId === booking.client_id._id"
                      src="https://emituscdn.com/insigniaClientDiner-arrow+(1).svg" alt="insignia client">
                   <div class="ml-2">
                      <span
                        class="text-basier">{{booking.price}} {{booking.currency === 'USD' ? '$' : '€'}}
                      </span>
                   </div>
                </div>
              </div>
              <div
                class="arrow-status-booking is-flex">
                <img
                  src="@/assets/images/booking/arrow>.svg"
                  alt="arrow">
              </div>
            </div>
          </div>
        </li>
      </ul>

      <!-- listing Missed it -->

      <div v-if="optionsSelect === 'history' && arrCancelledAndCompleted.length > 0" class="is-flex mb-3">
        <img
          class="mr-2"
          src="@/assets/images/booking/point-purple.svg"
          alt="point">
        <p
          class="text-basier is-mini-small-text gray-emitus"
          >
          Sesiones pasadas
        </p>
      </div>
      <ul
        v-if="showCancelledAndCompleted">
        <li
          v-for="(booking, idx) in arrCancelledAndCompleted"
          :key="idx + 'Ca'">
          <div
            @click="editBooking(booking._id, booking.professional_id.username)"
            v-if="booking.status === 'cancelled'" :class="userId === booking.professional_id._id ? 'cancelled-professional' : 'cancelled-client'"
            class="is-flex is-justify-content-space-between mt-4 container-upcomming-listing">
            <div
              class="content-booking-profesional">
              <div class="date-and-hour-booking">
                <h3
                  class="text-basier">
                  {{booking.startBook}}
                  <span> - {{booking.endBook}}</span>
                </h3>
              </div>
              <div>
                <p
                  v-if="userId === booking.professional_id._id"
                  class="text-basier">
                  / {{booking.client_id.firstname}} {{booking.client_id.lastname}}
                </p>
                <p
                  v-if="userId === booking.client_id._id"
                  class="text-basier">
                  / {{booking.professional_id.firstname}} {{booking.professional_id.lastname}}
                </p>
                <!-- <span
                  class="text-basier">
                  Lost {{booking.price}} {{booking.currency === 'USD' ? '$' : '€'}}
                </span> -->
              </div>
            </div>
            <div
              class="is-flex">
              <div
                class="is-flex is-flex-direction-column btn-confirm-bookings">
                <a
                  v-if="userId === booking.professional_id._id"
                  class="btn-approved btn-missed-it-bg btn-missed-it-bg">
                  Missed it
                </a>
                <a
                  v-if="userId === booking.client_id._id"
                  class="btn-approved btn-missed-it-bg btn-missed-it-client-bg">
                  Canceled
                </a>
                <span
                  v-if="userId === booking.professional_id._id"
                  class="text-basier white"
                >
                  Lost {{booking.price}} {{booking.currency === 'USD' ? '$' : '€'}}
                </span>
                <span
                  v-if="userId === booking.client_id._id"
                  class="text-basier">
                  {{booking.language.substr(0,2)}} ・ {{booking.price}} {{booking.currency === 'USD' ? '$' : '€'}}
                </span>
              </div>
              <div
                class="arrow-status-booking is-flex">
                <img
                  src="@/assets/images/booking/arrow>.svg"
                  alt="arrow">
              </div>
            </div>
          </div>
          <div
            @click="editBooking(booking._id, booking.professional_id.username)"
            v-if="booking.status === 'delivered'" :class="userId === booking.professional_id._id ? 'completed-professional' : 'completed-client'"
            class="is-flex is-justify-content-space-between mt-4 container-upcomming-listing">
            <div
              class="is-flex content-booking-profesional is-flex-direction-column">
              <div class="date-and-hour-booking">
                <h3
                  class="text-basier">
                  {{booking.startBook}}
                  <span> - {{booking.endBook}}</span>
                </h3>
              </div>
              <div>
                <div
                  v-if="booking.professional_id">
                  <div
                    class="is-flex" style="gap: 5px;"
                    v-if="userId === booking.professional_id._id && booking.client_id">
                    <p
                      v-if="booking.client_id.firstname && booking.client_id.firstname !== ''"
                      class="text-basier">
                      / {{booking.client_id.firstname}}
                    </p>
                    <p
                      v-else
                      class="text-basier">
                      / Cliente
                    </p>
                    <p
                      v-if="booking.client_id.lastname && booking.client_id.lastname !== ''"
                      class="text-basier">
                      {{booking.client_id.lastname}}
                    </p>
                  </div>
                </div>
                <div
                  v-if="booking.client_id">
                  <div
                    class="is-flex" style="gap: 5px;"
                    v-if="userId === booking.client_id._id && booking.professional_id" >
                    <p
                      v-if="booking.professional_id.firstname && booking.professional_id.firstname !== ''"
                      class="text-basier">
                      / {{booking.professional_id.firstname}}
                    </p>
                    <p
                      v-else
                      class="text-basier">
                      / Profesional
                    </p>
                    <p
                      v-if="booking.professional_id.lastname && booking.professional_id.lastname !== ''"
                      class="text-basier">
                      {{booking.professional_id.lastname}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="is-flex">
              <div
                class="is-flex is-flex-direction-column btn-confirm-bookings">
                <a
                  class="btn-approved btn-completed-bg">
                  Completed
                </a>
                <div
                  class="is-flex"
                  style="align-items: baseline;">
                  <img
                    class="mr-2"
                    v-if="userId === booking.professional_id._id"
                    src="https://emituscdn.com/insigniaProfessionalDiner-arrow+(1).svg"
                    alt="insignia profesional">
                  <img
                    class="mr-2"
                    v-if="userId === booking.client_id._id"
                    src="https://emituscdn.com/insigniaClientDiner-arrow+(1).svg" alt="insignia client">
                  <span
                    class="text-basier mr-2">
                    {{booking.price}} {{booking.currency === 'USD' ? '$' : '€'}}
                  </span>
                  <span class="text-basier white">|</span>
                  <input
                    class="value-rate Basier-normal-Medium ml-2"
                    v-model="booking.rateprofessional" min="0" :max="maxs"
                    :step="isDisabled ? '.1' : '1'">
                  <b-rate
                    size="is-small"
                    v-model="booking.rateprofessional"
                    :max="maxs"
                    :disabled="isDisabled">
                  </b-rate>
                </div>
              </div>
              <div
                class="arrow-status-booking is-flex">
                <img
                  src="@/assets/images/booking/arrow>.svg"
                  alt="arrow">
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import dayjs from 'dayjs'
import firebase from 'firebase/app'
import 'firebase/auth'
import BookingService from '@/services/BookingService'

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'bookings',
  components: {
  },
  data () {
    return {
      maxs: 1,
      isDisabled: true,
      bookings: [],
      user: {},
      authUser: null,
      userId: '',
      arrRequestprofessional: [],
      arrBookedAndRequest: [],
      arrCancelledAndCompleted: [],
      // arrCompleted: [],
      showCancelledAndCompleted: false,
      showRequest: true,
      showBooked: true,
      optionsSelect: 'all'
      // professional: '',
      // client: ''
    }
  },
  metaInfo: {
    title: 'emitus | Profile'
  },

  watch: {
    optionsSelect: function () {
      if (this.optionsSelect === 'all') {
        this.showCancelledAndCompleted = false
        this.showRequest = true
        this.showBooked = true
      }
      if (this.optionsSelect === 'history') {
        this.showCancelledAndCompleted = true
        this.showRequest = false
        this.showBooked = false
      }
    }
    // authUser: async function () {
    //   if (this.authUser) {
    //     // Ask for data to App
    //     await this.notify.$emit('giveMeData')
    //     // Get my Bookings
    //     firebase.auth().currentUser.getIdToken(true)
    //       .then(async idToken => {
    //         const myBookingsAsProfessional = await BookingService.getMyBookingsAsProfessional(idToken)
    //         const myBookingsAsClient = await BookingService.getMyBookingsAsClient(idToken)
    //         this.bookings.push(...myBookingsAsProfessional.data)
    //         this.bookings.push(...myBookingsAsClient.data)
    //         this.bookings.forEach(booking => {
    //           booking.startBook = dayjs(booking.startBook).format('MMM D, HH:mm')
    //           booking.endBook = dayjs(booking.endBook).format('HH:mm')
    //         })
    //         // console.log('myBookingsAsProfessional ->>', myBookingsAsProfessional)
    //         // console.log('myBookingsAsClient ->>', myBookingsAsClient)
    //         UserService.getCurrentUser(idToken)
    //           .then(user => {
    //             this.userId = user.data._id
    //             // array de bookigs por aprovar para el profecional
    //             const arrRequestprofessional = []
    //             this.bookings.map(function (e) {
    //               if (e.status === 'request' && e.professional_id._id === user.data._id) {
    //                 arrRequestprofessional.push(e)
    //               }
    //             })
    //             this.arrRequestprofessional = arrRequestprofessional.sort((a, b) => dayjs(a.startBook).unix() - dayjs(b.startBook).unix())
    //             // array de bookigs aprovados
    //             const arrBookedAndRequest = []
    //             this.bookings.map(function (e) {
    //               if (e.status === 'ready' || e.status === 'booked' || (e.status === 'request' && e.client_id._id === user.data._id)) {
    //                 arrBookedAndRequest.push(e)
    //               }
    //             })
    //             this.arrBookedAndRequest = arrBookedAndRequest.sort((a, b) => dayjs(a.startBook).unix() - dayjs(b.startBook).unix())
    //             // array de bookigs cancelados y completados
    //             const arrCancelledAndCompleted = []
    //             this.bookings.map(function (e) {
    //               if (e.status === 'cancelled' || e.status === 'delivered') {
    //                 arrCancelledAndCompleted.push(e)
    //               }
    //             })
    //             this.arrCancelledAndCompleted = arrCancelledAndCompleted.sort((a, b) => dayjs(a.startBook).unix() - dayjs(b.startBook).unix())

    //             // const arrCompleted = []
    //             // this.bookings.map(function (e) {
    //             //   if (e.status === 'completed') {
    //             //     arrCompleted.push(e)
    //             //   }
    //             // })
    //             // this.arrCompleted = arrCompleted.sort((a, b) => dayjs(a.startBook).unix() - dayjs(b.startBook).unix())
    //           })
    //       })
    //       .catch(error => {
    //         this.danger(error.response.data.msg)
    //       })
    //   }
    // }
  },
  async created () {
    // Get User form this.notify
    this.notify.$on('updateAuthUser', (user) => {
      this.authUser = user
    })
    firebase.auth().currentUser.getIdToken(true)
      .then(async idToken => {
        const myBookingsAsProfessional = await BookingService.getMyBookingsAsProfessional(idToken)
        const myBookingsAsClient = await BookingService.getMyBookingsAsClient(idToken)
        this.bookings.push(...myBookingsAsProfessional.data)
        this.bookings.push(...myBookingsAsClient.data)
        this.bookings.forEach(booking => {
          booking.startBook = dayjs(booking.startBook).format('MMM D, HH:mm')
          booking.endBook = dayjs(booking.endBook).format('HH:mm')
        })
        // console.log('myBookingsAsProfessional ->>', myBookingsAsProfessional)
        // console.log('myBookingsAsClient ->>', myBookingsAsClient)
        UserService.getCurrentUser(idToken)
          .then(user => {
            this.userId = user.data._id
            // array de bookigs por aprovar para el profecional
            const arrRequestprofessional = []
            this.bookings.map(function (e) {
              if (e.status === 'request' && e.professional_id._id === user.data._id) {
                arrRequestprofessional.push(e)
              }
            })
            this.arrRequestprofessional = arrRequestprofessional.sort((a, b) => dayjs(a.startBook).unix() - dayjs(b.startBook).unix())
            // array de bookigs aprovados
            const arrBookedAndRequest = []
            this.bookings.map(function (e) {
              if (e.status === 'ready' || e.status === 'booked' || (e.status === 'request' && e.client_id._id === user.data._id)) {
                arrBookedAndRequest.push(e)
              }
            })
            this.arrBookedAndRequest = arrBookedAndRequest.sort((a, b) => dayjs(a.startBook).unix() - dayjs(b.startBook).unix())
            // array de bookigs cancelados y completados
            const arrCancelledAndCompleted = []
            this.bookings.map(function (e) {
              if (e.status === 'cancelled' || e.status === 'delivered') {
                arrCancelledAndCompleted.push(e)
              }
            })
            this.arrCancelledAndCompleted = arrCancelledAndCompleted.sort((a, b) => dayjs(a.startBook).unix() - dayjs(b.startBook).unix())
          })
      })
      .catch(error => {
        this.danger(error.response.data.msg)
      })
    // window.location.reload()
  },
  async mounted () {
    // Ask for data to App
    await this.notify.$emit('giveMeData')
  },
  beforeDestroy () {
    this.notify.$off('updateAuthUser')
  },
  methods: {
    // Edit my booking by id
    async editBooking (idBooking, username) {
      this.$router.push(`/bookings/edit/${idBooking}/${username}`)
      this.notify.$emit('hiddeNavBarMobileStatusBooking')
    },
    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }
}
</script>

<style scoped>

.back-ground-dark {
  width: 100%;
  height: 100%;
  background-color: #000;
}

/* css status booking*/

.request-professional {
  background: #EE905F;
}

.request-professional:hover, .request-professional:active, .request-professional:focus {
  background: #DF966D;
  /*-webkit-box-shadow: 1px 1px 20px 1px rgba(255,161,10,0.37);
  box-shadow: 1px 1px 20px 1px rgba(255,161,10,0.37);*/
}

.request-client {
  background: #502EB3;
}

.request-client:hover, .request-client:active, .request-client:focus {
  background: #6E3DEA;
  /*-webkit-box-shadow: 1px 1px 17px 1px rgba(203,61,234,0.27);
  box-shadow: 1px 1px 17px 1px rgba(203,61,234,0.27);*/
}

.approved-professional {
  background: rgba(0, 193, 177, 0.88);
}

.approved-professional:hover, .approved-professional:active, .approved-professional:focus {
  background: rgba(0, 193, 177, 0.69);
  /*-webkit-box-shadow: 1px 1px 17px 1px rgba(1,153,140,0.20);
  box-shadow: 1px 1px 17px 1px rgba(1,153,140,0.20);*/
}

.ready-professional {
  background: #9A4BFF;
}

.ready-professional:hover, .ready-professional:active, .ready-professional:focus {
  background: #B887F6;
  /*-webkit-box-shadow: 1px 1px 17px 1px rgba(1,153,140,0.20);
  box-shadow: 1px 1px 17px 1px rgba(1,153,140,0.20);*/
}

.approved-client {
  background: rgba(0, 193, 177, 0.88);
}

.approved-client:hover, .approved-client:active, .approved-client:focus {
  background: rgba(0, 193, 177, 0.69);
  /*-webkit-box-shadow: 1px 1px 17px 1px rgba(1,153,140,0.17);
  box-shadow: 1px 1px 17px 1px rgba(1,153,140,0.17);*/
}

.ready-client {
  background: #9A4BFF;
}

.ready-client:hover, .ready-client:active, .ready-client:focus {
  background: #B887F6;
  /*-webkit-box-shadow: 1px 1px 17px 1px rgba(1,153,140,0.17);
  box-shadow: 1px 1px 17px 1px rgba(1,153,140,0.17);*/
}
.cancelled-professional {
  background: rgba(208, 62, 62, 0.76);
}

.cancelled-professional:hover, .cancelled-professional:active, .cancelled-professional:focus {
  background: rgba(208, 62, 62, 0.46);
  /*-webkit-box-shadow: 1px 1px 9px 0px rgba(208,8,8,0.10);
  box-shadow: 1px 1px 9px 0px rgba(208,8,8,0.10);*/
}

.cancelled-client {
  background: rgba(107, 108, 125, 0.58);
}

.cancelled-client:hover, .cancelled-client:active, .cancelled-client:focus {
  background: rgba(107, 108, 125, 0.28);
  /*-webkit-box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.15 );
  box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.15);*/
}

.completed-professional {
  background: rgba(233, 233, 233, 0.58);
}

.completed-professional:hover, .completed-professional:active, .completed-professional:focus {
  background: rgba(233, 233, 233, 0.38);
  /*-webkit-box-shadow: 1px 1px 13px 1px rgba(0,0,0,0.15);
  box-shadow: 1px 1px 13px 1px rgba(0,0,0,0.15);*/
}

.completed-client {
  background: rgba(233, 233, 233, 0.48);
}

.completed-client:hover, .completed-client:active, .completed-client:focus {
  background: rgba(233, 233, 233, 0.38);
  /*-webkit-box-shadow: 1px 1px 13px 1px rgba(0,0,0,0.23);
  box-shadow: 1px 1px 13px 1px rgba(0,0,0,0.23);*/
}

#container-general-session {
  width: 90%;
  min-height: 100vh;
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 100px;
}

  #container-general-session .select {
    border: 1px solid rgba(238, 238, 238, 0.2) !important;
    color: #fff;
    border-radius: 5px;
    background: #000;
    width: 100%;
    max-width: 321px;
    height: 44px !important;
    padding: 0 13px;
    margin-bottom: 44px !important;
  }

   #container-general-session .select:focus {
     color: #fff !important;
   }

   #container-general-session .select:hover {
     color: #fff !important;
   }

   #container-general-session .select:active {
     color: #fff !important;
   }
  .title-upcomming {
      margin-bottom: 38px;
  }
  .title-upcomming span {
      font-size: 54.8428px;
      line-height: 52px;
      text-align: right;
      color: #fff;
      margin-right: 18px;
  }
  .title-upcomming p {
      font-size: 17.8239px;
      line-height: 26px;
      color: #fff;
  }
  .select-bookings-2 {
      width: 100%!important;
  }

  /* bookings */
  #container-general-session  .rate .rate-item.set-on .icon, .rate .rate-item.set-half .is-half {
    color: #0D1239!important;
  }
  #container-general-session input {
    border: none;
    background: transparent;
    width: 13px;
    font-size: 12px!important;
    font-weight: bold;
    height: 18px!important;
  }
.btn-approved {
  border-radius: 15px;
  color: rgba(12, 13, 52, 0.9);
  font-size: 9px!important;
  padding: 0px 8px;
  text-align: center;
  font-weight: bold!important;
}
.btn-approved-bg {
  background: rgba(126, 223, 215, 0.8);
  width: 55px!important;
}
.btn-ready-bg {
  background: #CBA3FF;
}
.btn-to-be-approved-bg {
  background: #FEA878;
  /* width: 55px!important; */
}
.btn-to-be-approved-client-bg {
  background: #9E7BF6;
  /* width: 55px!important; */
}

.btn-completed-bg {
  background: rgba(236, 236, 236, 0.53);
}
.btn-missed-it-bg {
  background: rgba(255, 126, 123, 0.68);
}
.btn-missed-it-client-bg {
  background: #6D6E88;
}

.btn-confirm-bookings {
  justify-content: space-between;
  align-items: flex-end;
  padding-right: 8px;
  border-right: 1px solid rgba(238, 238, 238, 0.5);
}
.content-upcomming {
  width: 100%;
  margin: 58px auto 0 auto;
}
.arrow-status-booking {
  margin-left: 13px;
}

.container-upcomming-listing {
  /* background: #FAFAFA; */
  border-radius: 5px;
  padding: 7px 19px;
}
.container-upcomming-listing h3 {
  font-size: 16.5px;
  line-height: 22px;
  color: #fff;
  text-align: end;
}
.container-upcomming-listing p {
  font-size: 13px;
  line-height: 22px;
  color: #fff;
  font-weight: 500;
}
.container-upcomming-listing span {
  font-size: 12.5px;
  line-height: 22px;
  color: #fff;
  /* color: rgba(13, 18, 57, 0.6); */
}

.min-missing-videocall {
  font-size: 12.5px !important;
  text-transform: uppercase;
}
.btn-confirm-bookings a {
  color: #0D1239!important;
  font-size: 11px;
}
.btn-confirm-bookings a span {
  font-weight: 900!important;
}

@media (min-width: 768px) {

  .content-upcomming-1 {
    display: flex;
    justify-content: space-between
  }
  .content-upcomming {
    width: 60%;
  }

  .container-upcomming-listing {
    padding: 11px 28px;
  }

  .container-upcomming-listing h3 {
    margin-bottom: 5px;
  }

  .arrow-status-booking {
    margin-left: 25px;
  }
    .select-bookings-1 {
      width: 265px!important;
    }
    .content-upcomming-1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    #container-general-session {
      width: 60%;
      padding-top: 200px;
    }
    .content-upcomming-1 {
      display: flex;
      justify-content: space-between
    }
    .content-upcomming {
      width: 60%;
    }
  }

</style>
